.search-results-container {
    padding: 20px; /* Padding from the edges of the container */
    display: flex;
    flex-direction: column;
    align-items: center; /* This will center the cards in the middle */
    width: 100%;
  }

  .search-result {
    width: 90%; /* Adjust this value to set how wide you want the cards to be */
    max-width: 1200px; /* Maximum width of the cards */
    margin-bottom: 20px; /* Space between cards */
    /* Add other styles for the search result card */
  }
