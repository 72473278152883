.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #454545; /* Example background color */
  }

  .header-logo {
    height: 50px; /* Adjust based on your logo's size */
  }

  .package-submit-form {
    display: flex;
    align-items: center;
  }

  .package-url-input {
    margin-right: 10px;
    padding: 5px;
  }

  .submit-btn, .search-btn {
    padding: 10px 15px;
    background-color: #007bff; /* Example blue color */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }

  .submit-btn:hover, .search-btn:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }