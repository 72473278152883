/* src/App.css or src/components/SearchBar.css */

form {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

input[type="text"] {
  padding: 10px;
  font-size: 16px;
  border: 2px solid #ddd;
  border-radius: 4px 0 0 4px;
  outline: none;
}

button {
  padding: 10px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 0 4px 4px 0;
}

button:hover {
  background-color: #0056b3;
}
